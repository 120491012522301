import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts/Layout'
import LayoutArchive from '../layouts/LayoutArchive'


//Component
const PostsTemplate = ( props ) => {

  const data = props.data

  const current_meta = {
    meta_title:     data.wordpressAcfOptions.options.post_meta_title,
    meta_desc:      data.wordpressAcfOptions.options.post_meta_desc,
  }

  const category_items = data.allWordpressCategory.edges
  const archive_items = data.allWordpressPost.edges

  return (
    <div>
      <Layout 
        current_meta = {current_meta} 
      >

        <LayoutArchive 
          category_items = {category_items}
          archive_items = {archive_items}
          title_only_item = {true}
        />

      </Layout>
    </div>
  )
}


//Export
export default PostsTemplate


//Query
export const postsQuery = graphql`
query postsQuery{
  wordpressAcfOptions {
    options {
      post_meta_title
      post_meta_desc
    }
  }
  allWordpressCategory (sort: {fields: description, order: ASC }) {
    edges {
      node {
        name
        slug
        link
      }
    }
  }
  allWordpressPost {
    edges {
      node {
        id
        title
        link
        acf {
          heading
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 600, height: 400, cropFocus: CENTER) {
                  ...Fixed
                }
              }
            }
          }
        }
        categories {
          name
          slug
        }
      }
    }
  }
}
`