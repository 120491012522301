import React, {Component, Fragment} from 'react'
//import PropTypes from 'prop-types'

import Masonry from 'react-masonry-component'
import MenuCategory from '../components/MenuCategory'
import ArchiveItem from '../components/ArchiveItem'
import LinkBtn from '../components/LinkBtn'
import { imageLoadedStatus } from '../misc/utility.js'

//Component
class LayoutArchive extends Component {

  state = {
    current: (this.props.current_category) ? this.props.current_category : 'all',
    maxVisible: 12
  }

  handleChange = ( cat ) => {
    this.setState({
      current: cat,
      maxVisible: 12
    })
  }

  handleLoadMore = () => {
    this.setState({
      maxVisible: this.state.maxVisible + 6
    })
  }

  handleImagesLoaded = (imagesLoadedInstance) => { 

  }

  handleLayoutComplete = ( laidOutItems ) => {

  }

  containsObjectSlug = (objSlug, list) => {
    
    for (let i = 0; i < list.length; i++) {
      if (list[i].slug === objSlug) {
        return true
      }
    }

    return false
  }
  
  render () {

    const { archive_items, category_items, title_only_item } = this.props
    const maxVisible = this.state.maxVisible

    const masonryOptions = {
      transitionDuration: 0
    }

    let visible = 0

    let archiveItems = archive_items.filter((node) => {

      let categories = null;
      if ( node.node.categories ) {
        categories = node.node.categories

      } else if ( node.node.cpt_categories ) {
        categories = node.node.cpt_categories

      }


      if ( this.state.current === 'all' ) {

        return true

      } else {

        return this.containsObjectSlug( this.state.current, categories )

      }

    }).map((node, index) => {

      visible++
      const item = node.node
      const fixed = (item.acf.main_image && item.acf.main_image.localFile) ? item.acf.main_image.localFile.childImageSharp.fixed : null

      const title = ( item.acf.heading ) ? item.acf.heading : item.title
      const label = ( title_only_item ) ? null : item.acf.label

      let item_container_classes = [
        'col-12',
        'col-sm-6',
        'col-lg-4',
        'mb-3',
        'mb-md-4'
      ]

      //Image console
      imageLoadedStatus('LayoutArchive.js', (!!fixed), item.acf.main_image.alt_text)

      if ( index < maxVisible ) {

        return (
          <div key={index} className={item_container_classes.join(' ')}>
            <ArchiveItem
              link={item.link}
              title={title}
              label= {label}
              fixed={fixed}
              titleOnlyItem={title_only_item}
            />
          </div>
        )

      } else { 

        return null

      }
    })

    return (
      <Fragment>

        <section className="section section--first">
          <div className="container-fluid">
            <div className="row">
              <div className="col">

                <MenuCategory 
                  items = {category_items} 
                  current = {this.state.current}
                  onChange = {(cat) => this.handleChange(cat)}
                  className = "mt-0"
                />

              </div>
            </div>
          </div>
        </section>

        <section className="section section--archive-masonry">
          <div className="container-fluid">
            <Masonry
              className = {'masonry row'}
              elementType = {'ul'}
              options = {masonryOptions}
              enableResizableChildren = {true}
              disableImagesLoaded = {false}
              updateOnEachImageLoad = {true}
              onImagesLoaded = {this.handleImagesLoaded}
              onLayoutComplete = {laidOutItems => this.handleLayoutComplete(laidOutItems)}
            >

              {archiveItems}

            </Masonry>
          </div>
        </section>

        { visible > maxVisible ? (
          <section className="section section--archive-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-center mt-3">
                  <div>

                    <LinkBtn 
                      title="Visa fler"
                      onClick={() => this.handleLoadMore()} 
                    />

                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null }

      </Fragment>
    )
  }
}


//Props validation
/*Layout.propTypes = {
  children: PropTypes.node.isRequired,
}*/


//Export
export default LayoutArchive